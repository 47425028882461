.navbar {
    width: 100%;
    margin: 0;
    background-color: #4e59e7;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}
a.nav-link {
    text-transform: none;
}
button .navbar-toggler:hover, .navbar-toggler:focus {
    outline: none;
}

a.nav-link {
    border-bottom: 3px solid transparent;
}

a.nav-link:hover {
    border-bottom: 3px solid #fff;
    transition: 1s all;
}
.iconColor{
    color: white;
    cursor: pointer;
    padding: 10px;
}
.notifications{
    text-align: left;
    margin-bottom: 0;
    cursor: pointer;
}
@media screen and (max-width: 1023px){
    .navbar-nav a.nav-link:last-child {
        border-bottom: none;
    }
    .navbar-nav a.nav-link {
        border-bottom: 1px solid #fff;
    }
    header nav button.navbar-toggler {
        color: #fff !important;
        border-color: transparent !important;
    }
}

.chartHeading{
    padding: 0.5rem;
    font-size: larger;
    font-weight: 600;
}

.connect-btn {
    width: 100%
}

.btn-index {
    z-index: 1;
}