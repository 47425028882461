.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.blue {
  background-color: #4E59E7 !important;
}

.blue th {
  font-weight: normal;
  text-transform: uppercase;
}
.table th {
  color: #fff;
}
.table thead th {
  border: none;
}

table tr {
  border-bottom: 1px solid #eee;
}
table tr:last-child {
  border-bottom: none;
}
div#usersInfo table thead tr {
  background-color: #EFF2FF !important;
}
div#usersInfo table tbody tr {
  background-color: #eee !important;
}
#usersInfo th {
  padding: 5px 10px !important;
  color: #4E59E7;
  text-transform: none;
  border: 1px solid #4E59E7;
}
#usersInfo table {
  border-bottom: 1px solid #2d2d2d;
}
.toggleColumn{
  cursor: pointer;
  background-color: white;
  border: none;
}
.table-hover tbody tr:hover button.toggleColumn{
  background-color: rgba(223, 221, 221, 0.075);
}
button.toggleColumn:focus{
  outline: none;
}
.table .accordionRow > td {
  padding: 0;
}
.textTransformClass{
  text-transform: capitalize;
}

.table a{
  text-transform: none;
}

.react-bootstrap-table{
  margin-bottom: 15px;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table.table-responsive{
  min-height: 50vh;
}

/* Table */
.table-responsive {
  -webkit-box-shadow: 1px 1px 18px -8px rgba(0, 0, 0, 0.73);
  -moz-box-shadow: 1px 1px 18px -8px rgba(0, 0, 0, 0.73);
  box-shadow: 1px 1px 25px -10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.tableDropDodownStyle button{
  background-color: transparent;
  border:none;
  color: black;
}

.tableDropDodownStyle button.dropdown-toggle::after {
  display: none;
}

.dropdown-menu .dropdown-item{
  text-transform: initial;
}

#organization .tableDropDodownStyle > .btn-primary:hover,
#organization .tableDropDodownStyle > .btn-primary:active,
#organization .tableDropDodownStyle > .btn-primary.dropdown-toggle,
#organization .tableDropDodownStyle > .btn-primary:focus,
#requestsTable .tableDropDodownStyle > .btn-primary:hover,
#requestsTable .tableDropDodownStyle > .btn-primary:active,
#requestsTable .tableDropDodownStyle > .btn-primary.dropdown-toggle ,
#requestsTable .tableDropDodownStyle > .btn-primary:focus,
#transactionTable .tableDropDodownStyle > .btn-primary:hover,
#transactionTable .tableDropDodownStyle > .btn-primary:active,
#transactionTable .tableDropDodownStyle > .btn-primary.dropdown-toggle ,
#transactionTable .tableDropDodownStyle > .btn-primary:focus,
#orders .tableDropDodownStyle > .btn-primary:hover,
#orders .tableDropDodownStyle > .btn-primary:active,
#orders .tableDropDodownStyle > .btn-primary.dropdown-toggle ,
#orders .tableDropDodownStyle > .btn-primary:focus{
  background-color: unset ;
  border-color: unset;
  color: unset;
  box-shadow: unset;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(1 1 48 / 50%);
  backdrop-filter: blur(2px);
}

.loader img {
width: 60px;
}

.cursor-pointer {
  cursor: pointer;
}

.img-wallet{
  height: 40px;
}

/* CARD CSS */
.dashboard-request-card .request-card-padding {
  padding: 0.75rem 1rem;
}

.dashboard-request-card {
 min-height: 6rem;
 margin-bottom: 1rem;
 box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.06);
}
.request-card-padding p, .request-card-padding h3 {
  text-align: left;
}
.request-card-padding h3 {
  font-size: 12pt;
}

.dashboard-request-card.card {
  transition: all .2s ease-in-out;
}

.dashboard-request-card.card:hover {
  transform: scale(1.1);
}

/* BUTTON CSS */
.btn.SignUpButton {
  padding: 0.7rem 1rem;
  margin: 30px 10px;
  background-color: #4e59e7;
  border: transparent;
  border-radius:30px;
  font-weight: 300;
}

.btn.SignUpButton:focus{
  box-shadow: none ;
  background-color: #2A43C8 ;
}
a.SignUpButton {
  text-transform: inherit;
}

.submitButton {
   width: 15%;

}
.form-row .form-group select {
  border-radius: 50px;
  -webkit-appearance: none;
}

/* Generate Preview Button*/
#previewButton {
  border: 1px solid #eee;
  border-radius: 1npm 0px;
  padding: 20px;
}

@media screen and (max-width: 567px){
   .submitButton {
      width: 40%;
      margin: 10px !important;
  }
}

/* Modal CSS */
.btn.modalBtnBorder {
  border: 2px solid #4e59e7;
  background-color: white;
  color: #4e59e7;
}

.modal .modal-body input.textForm, .modal select.textForm.custom-select.dropdown.form-control {
  margin: 10px 0;
}

.modal button.SignUpButton.btn.btn-primary {
  padding: 10px 25px;
}
.modal .modal-footer {
  justify-content: center;
}

.modal .modal-body{
  text-align: center;
}
.modal label.form-check-label {
  text-align: left;
}

/* TextInput CSS */
.textForm,
.picky__input {
  border-radius: 50px !important;
  padding: 20px 0px 20px 12px !important;
  font-size: 12pt !important;
  -webkit-appearance: none;
}
input.textForm.form-control {
  font-size: 15px !important;
}
.textForm:focus {
  box-shadow: none !important;
  border-color: #393939 !important;
  color: #393939 !important;
}

.dropdown {
  box-sizing: border-box;
  padding: 4px 0px 4px 12px !important;
  height: calc(1.2em + 0.75rem + 12px) !important;
}

.picky__input {
  padding: 0px 20px !important;
  height: calc(1.2em + 0.75rem + 12px) !important;
}
.picky__input::after {
  top: 20px !important;
}
.picky__input:focus {
  outline: none !important;
}
button.picky__input {
  border-color: #c7c8ce;
}
button.picky__input:focus {
    border-color: #393939;
}
label {
  margin-left: 10px;
}
/* Type Date */
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.3em;
}

/* CheckBox*/

.mb-3 .form-check {
  display: inline-block;
  padding-left: 2.25rem;
  padding-top: 0.5rem;
}

/* Type Color */

input[type="color"] {
  width: 50px;
  height: 35px;
  margin-left: 10px;
  border: 1px solid transparent;
}

.color label.form-label {
  margin-bottom: 0px;
}

#previewButton label.form-label{
  display: block;
}


/* Type Range */
input.slider {
  display: block;
  margin-left: 10px;
  margin-top: 10px;
  width: 90%;
}

.rangeType span {
  margin-left: 5px;
}

/* Form Title  and  Text Area */
.formTitle label.form-label {
  margin-bottom: 10px;
  margin-top: 15px;
}

input.placeholderReadOnly.form-control::placeholder, .textAreaDisabled.form-control::placeholder {
  color: #495057;
}

.textAreaDisabled.form-control {
  border-radius: 10px;
}

.copyCode {
  margin-bottom: 50px;
}
