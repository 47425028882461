
  /*Common Css */
  
  h2, h3 {
    text-align: center;
    color: #393939;
    font-weight: 500;
  }
  
  h1{
    font-size:  24pt !important;
  }

  h2{
    font-size:  20pt !important;
  }
  
  p{
    font-size: 12pt !important;
    text-align: center;
  }
  
  a{
    color: #4E59E7;
    font-size: 12pt !important;
    text-decoration: none;
  }
  
  a:hover{
    text-decoration: none !important;
  }



  